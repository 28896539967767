import React from "react";
import { ApolloProvider } from "react-apollo";
import { CookiesProvider } from "react-cookie";
import client from "./src/gatsby-theme-apollo/client";

export const wrapRootElement = ({ element }) => (
  <ApolloProvider client={client}>
    <CookiesProvider>{element}</CookiesProvider>
  </ApolloProvider>
);
