import { ApolloClient, HttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "apollo-link-context";
import fetch from "isomorphic-fetch";

const httpLink = new HttpLink({
  uri: "https://allianz-quizz-api.soixanteseize.tech/graphql", //"http://localhost:3000/graphql",
  fetch,
});

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      //"x-api-key": AppSyncConfig.apiKey,
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export default client;
